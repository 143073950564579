import {
  PaymentsApi,
  ProductsListProductsInner,
} from '@focusrite-novation/ampify-api';
import {Currency} from '@focusrite-novation/ampify-web-ui';
import {getPlanForSoftwareId} from '../lib/plans';
import {getProductForSoftwareId} from '../lib/purchases';

export const formatPrice = (currency: Currency, value: number): string => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
  }).format(value);
};

export const getPerpetualPrice = async (
  ampifyApi: PaymentsApi,
  softwareId: string
) => {
  const product: ProductsListProductsInner = await getProductForSoftwareId(
    ampifyApi,
    softwareId
  );
  return product.price?.USD!.toFixed(2);
};

export const getRTOPrice = async (
  ampifyApi: PaymentsApi,
  softwareId: string
) => {
  const plan = await getPlanForSoftwareId(ampifyApi, softwareId);
  return plan.price?.USD!.toFixed(2);
};
