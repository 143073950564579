import {ButtonVariant} from '@focusrite-novation/ampify-web-ui';
import React, {FC} from 'react';
import Theme from '../../theme';
import {PurchaseCopy} from '../../types/Product';
import * as Styles from './PaymentOption.styles';

interface PaymentOptionProps {
  url: string;
  title: string;
  description: string;
  copy: PurchaseCopy;
  price: string;
  testId: string;
  discount: string | null;
  buttonText: string;
  colour?: string;
}

interface TickProps {
  colour: string;
}

const renderDiscount = (price: string, discount: string | null) => {
  return discount ? (
    <b>
      <s>{price}</s> {discount}
    </b>
  ) : (
    <b>{price}</b>
  );
};

const renderDescription = (
  description: string,
  price: string,
  discount: string | null
) => {
  return discount ? (
    <span
      dangerouslySetInnerHTML={{
        __html: description.replace('{price}', `<s>${price}</s> ${discount}`),
      }}
    ></span>
  ) : (
    description.replace('{price}', price)
  );
};

const Tick: FC<TickProps> = ({colour}) => (
  <Styles.TickStyle
    height="26px"
    width="26px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    colour={colour}
  >
    <path d="M31.43 6.599 28.4 3.57A1.987 1.987 0 0 0 27 3a2.06 2.06 0 0 0-1.412.576L11 18.165l-4.588-4.588A2.056 2.056 0 0 0 5 13c-.335 0-.923.092-1.4.57L.57 16.6A1.987 1.987 0 0 0 0 18c0 .479.194 1.029.576 1.412l9.008 9.006c.361.36.864.582 1.416.582.556 0 1.066-.235 1.416-.584L31.424 9.412A2.06 2.06 0 0 0 32 8a1.99 1.99 0 0 0-.57-1.401z" />
  </Styles.TickStyle>
);

export const PaymentOption: FC<PaymentOptionProps> = ({
  colour = Theme.colours.greys[4],
  url,
  title,
  description,
  copy,
  price,
  testId,
  discount,
  buttonText,
}) => {
  return (
    <Styles.Wrapper colour={colour}>
      <Styles.Option colour={colour}>
        <h3>{title}</h3>
        {renderDiscount(price, discount)}

        <p>{description}</p>
        <Styles.PurchaseButton
          href={url}
          variant={ButtonVariant.MONOCHROME}
          data-testid={testId + '-button'}
        >
          {buttonText}
        </Styles.PurchaseButton>
      </Styles.Option>
      <Styles.Points>
        {copy.purchaseInfo.map((info, index) => (
          <Styles.Point key={'option-' + index} colour={colour}>
            <Tick colour={colour} />
            <h3>{info.title}</h3>
            <p>{renderDescription(info.description, price, discount)}</p>
          </Styles.Point>
        ))}
      </Styles.Points>

      <Styles.PurchaseButtonMobile
        href={url}
        variant={ButtonVariant.MONOCHROME}
        data-testid={testId + '-button-mobile'}
      >
        {buttonText}
      </Styles.PurchaseButtonMobile>
    </Styles.Wrapper>
  );
};
