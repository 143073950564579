import styled, {css} from 'styled-components';
import Theme from '../../theme';
import {Button} from '../Button';

interface ThemeProps {
  colour: string;
}

export const Wrapper = styled.section<ThemeProps>`
  ${({colour}) => css`
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-gap: 4rem;
    padding: 2rem;
    border: 2px solid ${colour};
    font-size: ${Theme.fontSizes[2]}px;
    box-shadow: 1px 5px 20px rgba(124, 124, 124, 0.2);

    h3 {
      padding: 0px;
      font-size: ${Theme.fontSizes[5]}px;
    }

    @media screen and (max-width: ${Theme.breakpoints[2]}px) {
      grid-template-columns: 25% 1fr;
      grid-gap: 2rem;
    }

    @media screen and (max-width: ${Theme.breakpoints[1]}px) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
  `}
`;

export const Points = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    margin-top: -1rem;
  }
`;

export const Point = styled.div<ThemeProps>`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-content: start;

  ${({colour}) => css`
    h3 {
      color: ${colour};
    }
  `}
`;

interface TickStyleProps {
  colour: string;
}

export const TickStyle = styled.svg<TickStyleProps>`
  ${({colour}) => css`
    fill: ${colour};
    grid-row: 1 / span 2;
  `}
`;

export const Option = styled.div<ThemeProps>`
  ${({colour}) => css`
    display: flex;
    flex-direction: column;
    font-size: ${Theme.fontSizes[3]}px;

    h3 {
      color: ${colour};
      text-transform: uppercase;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid ${colour};
    }

    p {
      padding-bottom: 1rem;
      font-size: ${Theme.fontSizes[2]}px;
      line-height: 1.5;
    }

    & > b > s {
      color: ${Theme.colours.greys[4]};
    }
  `}
`;

export const PurchaseButton = styled(Button)`
  width: 100%;
  margin-top: auto;
  text-transform: uppercase;

  :focus-visible {
    outline: ${Theme.colours.blue} solid 3px;
  }

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    display: none;
  }
`;

export const PurchaseButtonMobile = styled(PurchaseButton)`
  display: none;
  text-align: center;

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    display: block;
  }
`;
