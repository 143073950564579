export interface TitledDescription {
  title: string;
  description: string;
}

export enum StepButtonType {
  DOWNLOAD_PLUGIN = 'DOWNLOAD-PLUGIN',
  CUSTOM_BUTTON = 'CUSTOM',
}

export interface GettingStartedPage {
  pageTitle: string;
  relationships: {
    steps: GettingStartedStep[];
  };
}

export interface GettingStartedStep {
  body: string;
  mobileBody?: string;
  colour: string;
  title: string;
  videoId?: string;
  relationships: {
    button?: GettingStartedButton;
  };
}

interface GettingStartedButton {
  isDownloadButton: boolean;
  link?: {
    title: string;
    uri: string;
  };
}

export interface Product {
  purchaseHero: TitledDescription;
}

export interface PurchaseCopy {
  intro: TitleText;
  purchaseInfo: TitleText[];
  purchaseNote: string;
  planTerms?: PlanTerms;
  perpetualTerms?: PlanTerms;
}

interface TitleText {
  title: string;
  description: string;
}

interface PlanTerms {
  text: string;
  url: string;
}

export enum ProductType {
  Subscription,
  Perpetual,
}
