import React, {useEffect, useState, FC, ReactNode} from 'react';
import {useLocation} from '@reach/router';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import styled from 'styled-components';
import {graphql, navigate, useStaticQuery} from 'gatsby';
import {ProductType, PurchaseCopy} from '../types/Product';
import {Theme} from '../theme/Theme';
import {FooterContent} from '../types/FooterContent';
import * as loginUrl from '../utils/login-url';
import {getPlanForSoftwareId} from '../lib/plans';
import {createApi} from '../lib/api';
import {
  PaymentsApi,
  ProductsListProductsInner,
} from '@focusrite-novation/ampify-api';
import {PlanSelectionPageQuery} from './__generated__/PlanSelectionPageQuery';
import {OAuthOptions} from '../utils/auth';
import {getProductForSoftwareId} from '../lib/purchases';
import {ContentfulProduct} from '../types/ContentfulProductContent';
import {isChosenPlugin} from '../lib/payment';
import {formatPrice} from '../utils/get-plan-pricing';
import {Currency} from '@focusrite-novation/ampify-web-ui';
import {ProductV2} from '../types/ProductV2';
import {Spacer} from '../components/Spacing/Spacer';
import {PaymentOption} from '../components/PaymentOption';

const Logo = styled.img`
  display: block;
  margin: auto;
  width: 400px;
  height: 150px;

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    width: 300px;
    height: 80px;
  }
`;

const PurchaseNote = styled.p`
  font-size: ${Theme.fontSizes[2]}px;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const PaymentsLink = styled.a`
  display: block;
  text-align: center;
  font-size: ${Theme.fontSizes[2]}px;
  color: ${Theme.colours.greys[8]};
`;

interface PlanSelectionPageProps {
  pageContext: {
    contentfulProduct: ContentfulProduct | {node: ProductV2};
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
    rent: PurchaseCopy;
    buyNow: PurchaseCopy;
  };
}

const PlanSelectionPage: FC<PlanSelectionPageProps> = (
  props: PlanSelectionPageProps
) => {
  const [rentUrl, setRentUrl] = useState<string>('');
  const [perpetualUrl, setPerpetualUrl] = useState<string>('');
  const [subscriptionPrice, setSubscriptionPrice] = useState<string>('');
  const [subscriptionTerms, setSubscriptionTerms] = useState<number>(15);
  const [perpetualPrice, setPerpetualPrice] = useState<string>('');

  const data: PlanSelectionPageQuery = useStaticQuery(getPlanSelectionQuery);

  const contentfulProduct = props.pageContext.contentfulProduct.node;
  const rent: PurchaseCopy = props.pageContext.rent;
  const buyNow: PurchaseCopy = props.pageContext.buyNow;

  const location = useLocation();

  const getUrlOptions = (
    urlType: ProductType,
    customerPortalBaseUrl: string,
    optionalVals: {itemId?: string; planId?: string}
  ): loginUrl.PurchaseUrlOptions => {
    const options: loginUrl.PurchaseUrlOptions = {
      customerPortalBaseUrl,
      productName: contentfulProduct.title || '',
      softwareId: contentfulProduct.softwareId,
      slug: contentfulProduct.slug,
    };

    if (urlType === ProductType.Perpetual) {
      options.itemId = optionalVals.itemId;
    } else if (urlType === ProductType.Subscription) {
      options.planId = optionalVals.planId;
    }

    return options;
  };

  const getPrice = (product: ProductsListProductsInner): string => {
    const price = Number(product.price?.USD!.toFixed(2));
    return formatPrice(Currency.USD, price);
  };

  useEffect(() => {
    (async () => {
      const siteMetadata = data.site?.siteMetadata;
      const baseUrl = siteMetadata?.ampifyApi?.baseUrl as string;
      const oauth = siteMetadata?.auth as OAuthOptions;
      const ampifyApi = createApi(PaymentsApi, baseUrl, oauth);
      const softwareId = contentfulProduct.softwareId.toLowerCase();
      try {
        const plan = await getPlanForSoftwareId(ampifyApi, softwareId);
        const product = await getProductForSoftwareId(ampifyApi, softwareId);

        const rentOptions = getUrlOptions(
          ProductType.Subscription,
          props.pageContext.customerPortalBaseUrl,
          {planId: plan.id}
        );

        const perpetualOptions = getUrlOptions(
          ProductType.Perpetual,
          props.pageContext.customerPortalBaseUrl,
          {itemId: product.id}
        );

        setPerpetualPrice(getPrice(product));
        setSubscriptionPrice(getPrice(plan));
        setSubscriptionTerms(plan.term || 15);
        setRentUrl(loginUrl.buildPurchaseUrl(rentOptions));
        setPerpetualUrl(loginUrl.buildPurchaseUrl(perpetualOptions));
      } catch (error) {
        console.error(error);
        navigate('/404');
      }
    })();
  }, []);

  return (
    <Layout
      {...props.pageContext.contentfulFooterContent}
      customerPortalBaseUrl={props.pageContext.customerPortalBaseUrl}
    >
      <SEO title="Plan selection" />
      <Gutters maxWidth={GutterMaxWidth.LARGE}>
        <Logo
          src={contentfulProduct.productLogoBlack.file.url}
          data-testid="product-logo"
        />

        <PaymentOption
          url={perpetualUrl}
          title="Buy now"
          description="one time payment"
          copy={buyNow}
          price={perpetualPrice}
          testId="purchase"
          discount={
            isChosenPlugin('fast-bundle', location)
              ? formatPrice(Currency.USD, 251.89)
              : null
          }
          buttonText="Buy now"
          colour={Theme.colours.blue}
        />

        <Spacer />

        <PaymentOption
          url={rentUrl}
          title="Monthly Payments"
          description="for 15 months, with unlimited access thereafter"
          copy={rent}
          price={subscriptionPrice}
          testId="subscribe"
          discount={
            isChosenPlugin('fast-bundle', location)
              ? formatPrice(Currency.USD, 16.79)
              : null
          }
          buttonText="Setup&nbsp;Payments"
          colour={Theme.colours.orange}
        />
        <PurchaseNote>{rent.purchaseNote}</PurchaseNote>
        {rent.planTerms && (
          <PaymentsLink
            href={rent.planTerms.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {rent.planTerms.text}
          </PaymentsLink>
        )}
        {buyNow.perpetualTerms && (
          <PaymentsLink
            href={buyNow.perpetualTerms.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {buyNow.perpetualTerms.text}
          </PaymentsLink>
        )}
      </Gutters>
    </Layout>
  );
};

const getPlanSelectionQuery = graphql`
  query PlanSelectionPageQuery {
    heroBackground: file(
      relativePath: {eq: "fast-equaliser-hero-purchase.png"}
    ) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        auth {
          client_id
          scope
        }
        ampifyApi {
          baseUrl
        }
        ukVATMultiplier
      }
    }
  }
`;

export default PlanSelectionPage;
